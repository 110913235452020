import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

// layouts:
import Barra from "./pages/layout/barra.jsx";
import Contacto from "./pages/layout/Contacto.jsx";

// page components:
import HeroPrincipal from "./pages/principal/HeroPrincipal.jsx";
import Somos from "./pages/principal/Somos.jsx";
import Servicios from "./pages/principal/Servicios.jsx";
import ItemsServicios from "./pages/principal/ItemsServicios.jsx";
import ProductosMateriales from "./pages/principal/ProductosMateriales.jsx";
import BannerSection from "./pages/principal/BannerSection.jsx";
// import Tienda from './pages/principal/Tienda.jsx';
import Clientes from "./pages/principal/Clientes.jsx";
import Sucursales from "./pages/principal/Sucursales.jsx";
import SucursalesGuadalajara from "./pages/principal/SucursalesGuadalajara";

function App() {
  return (
    <div className="App">
      <Barra />
      <HeroPrincipal />
      <Somos />
      <Servicios />
      <ItemsServicios />
      <ProductosMateriales />
      <BannerSection />
      <Clientes />
      <Sucursales />
      <SucursalesGuadalajara />
      <Contacto />
    </div>
  );
}

export default App;
