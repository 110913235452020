import React from 'react';

const ItemsServicios = () => {
    return ( 
        <div className="items_servicios d-flex flex-column align-items-center justify-content-center">
            <div className="d-flex justify-content-around items_1">
                <p className="font-weight-bold">ARMAS</p>
                <p>GORRAS</p>
                <p className="font-weight-bold">AJEDREZ</p>
                <p>BILLETES</p>
                <p className="font-weight-bold">CHAMARRAS</p>
                <p>GUANTES DE BOX</p>
                <p className="font-weight-bold">JUGUETES</p>
                <p>MEDALLAS</p>
                <p className="font-weight-bold">ROMPECABEZAS</p>
                <p>OLEOS</p>
                <p className="font-weight-bold">LITOGRAFIA</p>
            </div>
            <div className="d-flex justify-content-around items-2">
                <p>DIBUJOS</p>
                <p className="font-weight-bold">FOTOGRAFIAS</p>
                <p>MONEDAS</p>
                <p className="font-weight-bold">RECONOCIMIENTOS</p>
                <p>ACUARELAS</p>
                <p className="font-weight-bold">Y MÁS...</p>
            </div>
        </div>
     );
}
 
export default ItemsServicios;