import React, {useState} from 'react';
import emailjs from 'emailjs-com';

import fondo_somos from '../../img/fondo_somos.jpg';

const Contacto = () => {

    const[enviado, guardarEnviado] = useState(false);

    function sendEmail(e){
        e.preventDefault();

        emailjs.sendForm('service_tzdcmad', 'template_iz64ut5', e.target, 'user_J3qX6bUAPtJZFT4VI9g1B')
        .then((result) => {
            console.log(result.text);
            guardarEnviado(true);
        }, (error) => {
            console.log(error.text);
        });
        guardarEnviado(true);
        e.target.reset();
        setTimeout(() => {
            guardarEnviado(false);
        }, 4000);
    }


    return ( 
        <footer id='footer'
            style={{
                backgroundImage: `url(${fondo_somos})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize:'cover',
                backgroundPosition:'center',
                height: '100vh',
                overflow:'hidden'
            }}
        >
                <div className="d-flex flex-column align-items-center justify-content-center formulario">
                    <h1 className="text-center">CONTÁCTANOS</h1>
                    <h3 className="text-center">VENTAS/QUEJAS/DUDAS/PROMOCIONES/COMENTARIOS</h3>
                    <form 
                        action="#" 
                        className="mt-5 form_contact" 
                        onSubmit={sendEmail}
                    >
                        <div className="form-group">
                            <input 
                                type="text"
                                name='nombre'
                                placeholder="NOMBRE" 
                                className="form-control mb-4 text-center"
                                required
                            />
                        </div>
                        <div className="form-group">
                            <input 
                                type="text" 
                                name='email'
                                placeholder="CORREO" 
                                className="form-control mb-4 text-center"
                                required
                            />
                        </div>
                        <div className="form-group">
                            <input 
                                type="number" 
                                name='telefono'
                                placeholder="TELÉFONO" 
                                className="form-control mb-4 text-center"
                                required
                            />
                        </div>
                        <div className="form-group">
                            <input 
                                type="text" 
                                name='asunto'
                                placeholder="ASUNTO" 
                                className="form-control mb-4 text-center"
                                required
                            />
                        </div>
                        <div className="form-group">
                            <textarea 
                                name="comentario" 
                                id="" 
                                cols="50" rows="8" 
                                placeholder="COMENTARIO" 
                                className="form-control text-center"
                                required
                                ></textarea>
                        </div>
                        { enviado ? <p className='mensaje_enviado'>Se ah enviado con exito tu correo, en breve nos comunicaremos contigo</p> : null}
                        <div className="botones d-flex justify-content-end">
                            <button className="btn text-decoration-none" type='submit'>ENVIAR</button>
                        </div>
                    </form>
                </div>
            </footer>
     );
}
 
export default Contacto;