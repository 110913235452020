import React from "react";
import { Card } from "react-bootstrap";

import logoRoma from "../../img/logoromaCompleto.png";

const Sucursales = () => {
  return (
    <section className="fondo_sucursales" id="sucursales">
      <h1 className="text-center mt-5 mb-5">Sucursales CDMX</h1>
      <div className="row aw-grid contenedor_sucursales">
        <div className="col-md-8 colum_1 mt-5">
          <Card style={{ width: "18rem", height: "100%" }} bg="dark">
            <Card.Img variant="top" src={logoRoma} alt="logo marcos romas" />
            <Card.Body>
              <Card.Title className="text-white">Sucursal Roma</Card.Title>
              <Card.Text className="text-white">Coahuila No.113</Card.Text>
              <Card.Text className="text-white">
                Entre Xalapa y Tonalá
              </Card.Text>
              <Card.Text className="text-white">Col.Roma C.P. 06700</Card.Text>
              <Card.Text className="text-white">
                Tels : 55 5574-1158 y
              </Card.Text>
              <Card.Text className="text-white">55 5584-0610</Card.Text>
            </Card.Body>
            <Card.Footer className="card_footer text-center">
              <a
                href="https://goo.gl/maps/BUq74gTLa5nMZUSy5"
                className="text-decoration-none"
                target="_blank"
                rel="noopener noreferrer"
                title="Roma Cuauhtémoc"
              >
                Ver Ubicación
              </a>
            </Card.Footer>
          </Card>
        </div>
        <div className="col-md-4 colum_2 mt-5 mb-5">
          <Card style={{ width: "18rem", height: "100%" }} bg="dark">
            <Card.Img variant="top" src={logoRoma} alt="logo marcos romas" />
            <Card.Body>
              <Card.Title className="text-white">Sucursal Narvarte</Card.Title>
              <Card.Text className="text-white">Xola No. 1367</Card.Text>
              <Card.Text className="text-white">Esquina Xochicalco</Card.Text>
              <Card.Text className="text-white">
                Col.Narvarte C.P. 03020
              </Card.Text>
              <Card.Text className="text-white">Tel: 55 5530-4783</Card.Text>
            </Card.Body>
            <Card.Footer className="card_footer text-center">
              <a
                href="https://g.page/marcos-y-molduras-roma?share"
                className="text-decoration-none"
                target="_blank"
                rel="noopener noreferrer"
                title="Roma Benito Juárez"
              >
                Ver Ubicación
              </a>
            </Card.Footer>
          </Card>
        </div>
      </div>
    </section>
  );
};

export default Sucursales;
