import React from 'react';

import estrella from '../../img/estrella.png';
import marco_principal_somos from '../../img/marco_principal_somos.png';
import submarco_rojo from '../../img/submarco_rojo.png';
import marco_principal_somos_2 from '../../img/marco_principal_somos_2.png';
import submarco_amarillo from '../../img/submarco_amarillo.png';

const Somos = () => {
    return ( 
        <div className="somos" id='somos'>
            <div className="somos_info d-flex flex-column align-items-center justify-content-center">
                <div className="marcos_molduras d-flex justify-content-end">
                    <h1 className="text-uppercase">somos</h1>
                </div>            
                <div className="marcos_molduras d-flex justify-content-end">
                    <img src={estrella}className="img-fluid mr-3 estrella" alt='imagen estrella' />
                    <img src={estrella}className="img-fluid mr-3 estrella" alt='imagen estrella' />
                    <img src={estrella}className="img-fluid mr-3 estrella" alt='imagen estrella' />
                    <h3 className="text-uppercase text-white">marcos y molduras</h3>
                </div>
                <div className="marcos_molduras">
                    <img src={marco_principal_somos} className="img-fluid marco_principal_somos" alt='imagen principal somos' />
                    <img src={submarco_rojo} className="img-fluid submarco_rojo" alt='submarco rojo' />
                    <div className="d-flex justify-content-end text-right info_somos">
                        <p className="text-white"> <span>Proveemos servicios de enmarcado de alta calidad. </span><br /> 
                            Somos innovadores y creativos, hemos perfeccionado estilos de <br /> enmarcado, todo es posible. 43 años de experiencia nos respaldan
                        </p>
                    </div>
                    <p className="text-white text-uppercase ml-5 info2_somos font-weight-bold">mas de 2000 modelos en exhibicion</p>
                    <p className="text-white text-uppercase ml-5 info2_somos">25,000 MONTAJES ANUALES . 2 SUCURSALES . CDMX 
                    </p>
                    <img src={marco_principal_somos_2} className="img-fluid marco_principal_somos_2" alt='imagen principal somos 2' />
                    <img src={submarco_amarillo} className="img-fluid submarco_amarillo" alt='submarco amarillo' />
                </div>
            </div>
        </div>
     );
}
 
export default Somos;