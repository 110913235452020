import React from 'react';

const BannerSection = () => {
    return ( 
        <div className="section_banners">
            <div className="coffee_solves ">
                <div className="contenedor_coffee d-flex flex-column align-items-start justify-content-center">
                    <h1>
                        MARCOS SOBRE TELA
                        <span>MADERA</span>
                    </h1>
                </div>
            </div>
            <div className="acrilico">
                <div className="contenedor_acrilico d-flex flex-column align-items-end justify-content-center">
                    <h1>
                        MOLDURA MADERA 
                        <span>ACRÍLICO</span>
                    </h1>
                </div>
            </div>
        </div>
     );
}
 
export default BannerSection;