import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { Link } from 'react-scroll';

import logo_roma_completo from '../../img/logoromaCompleto.png';

const Barra = () => {
    return ( 
        <Navbar bg="light" expand="lg" className='nav_principal fixed-top'>
        <Navbar.Brand href="/">
            <Nav.Link>
                <img src={logo_roma_completo} alt="logo marcos roma" className='img-fluid'/>
            </Nav.Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" bg="light"/>
        <Navbar.Collapse id="basic-navbar-nav" bg="light">
        <Nav className="ml-auto nav_items">
            <Nav.Link>
                <Link to='inicio' smooth={true} duration={1000} className='nav_name link_nav text-decoration-none  text-white text-uppercase'>inicio</Link>
            </Nav.Link>
            <Nav.Link>
                <Link to='somos' smooth={true} duration={1000} className='nav_name link_nav text-decoration-none text-white text-uppercase'>somos</Link>
            </Nav.Link>
            <Nav.Link>
                <Link to='servicios' smooth={true} duration={1000} className='nav_name link_nav text-decoration-none text-white text-uppercase'>servicios</Link>
            </Nav.Link>
            <Nav.Link>
                <Link to='productos_materiales' smooth={true} duration={1000} className='nav_name link_nav text-decoration-none text-white text-uppercase'>productos y materiales</Link>
            </Nav.Link>
            {/* <Nav.Link>
                <Link to='' smooth={true} duration={1000} className='nav_name link_nav text-decoration-none text-white text-uppercase'>tienda</Link>
            </Nav.Link> */}
            <Nav.Link>
                <Link to='clientes' smooth={true} duration={1000} className='nav_name link_nav text-decoration-none text-white text-uppercase'>clientes</Link>
            </Nav.Link>
            <Nav.Link>
                <Link to='sucursales' smooth={true} duration={1000} className='nav_name link_nav text-decoration-none text-white text-uppercase'>sucursales</Link>
            </Nav.Link>
            <Nav.Link>
                <Link to='footer' smooth={true} duration={1000} className='nav_name link_nav text-decoration-none text-white text-uppercase'>contacto</Link>
            </Nav.Link>
        </Nav>
        </Navbar.Collapse>
    </Navbar>
     );
}
 
export default Barra;