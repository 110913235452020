import React from 'react';

import cuadro_gris from '../../img/cuadrado_gris.png';

const ProductosMateriales = () => {
    return ( 
        <div className="materiales" id='productos_materiales'>
            <div className="row aw-grid">
                <div className="col-12 col-xs-12 col-sm-12 col-md-6 fondo_materiales">
                    <div className="encabezado_materiales d-flex flex-column align-items-center justify-content-center">
                        <div className="d-flex justify-content-start w-100">
                            <h1 className="text-white font-weight-bold">PRODUCTOS</h1>
                        </div>
                        <div className="d-flex justify-content-end w-100">
                            <h1 className="font-weight-bold material">MATERIALES</h1>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-xs-12 col-sm-12 col-md-6 listado_materiales">
                    <div className="row aw-grid mt-5 cuadrado_gris">
                        <div className="col-md-2 ">
                            <img src={cuadro_gris} alt="cuadro gris" className="img-fluid " />
                        </div>
                        <div className="col-md-10 titulo_materiales">
                            <h3>
                                Contamos con diferentes productos y materiales
                                <span>que estamos seguros complementaran su</span>
                                <span>enmarcado, para darle un estilo especial a su obra</span>
                            </h3>
                        </div>
                    </div>
                    <div className="info_materiales mt-5">
                        <div className="row aw-grid">
                            <div className="col-12 col-xs-12 col-sm-12 col-md-6 d-flex flex-column justify-content-start columna_1">
                                <div className="perfiles mb-3">
                                    <h4 className="mb-4 text-uppercase">MOLDURAS/PERFILES</h4>
                                    <ul>
                                        <li>Aluminio</li>
                                        <li>Madera</li>
                                        <li>MDF</li>
                                        <li>Poliuretano</li>
                                    </ul>
                                </div>
                                <div className="marialuisas">
                                    <h4 className="mb-4 text-uppercase">MARIALUISA/PASPARTÚ</h4>
                                    <ul>
                                        <li>Lisas</li>
                                        <li>Libre de ácido</li>
                                        <li>Tela</li>
                                        <li>Terciopelo</li>
                                        <li>Textura</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-12 col-xs-12 col-sm-12 col-md-6 d-flex flex-column justify-content-start columna_2">
                                <div className="traseras mb-3">
                                    <h4 className="mb-4 text-uppercase">Traseras/Respaldo</h4>
                                    <ul>
                                        <li>Cartón</li>
                                        <li>Foam Board</li>
                                        <li>Libre de ácido</li>
                                        <li>MDF</li>
                                        <li>PVC Espumado</li>
                                    </ul>
                                </div>
                                <div className="vidrio mb-3">
                                    <h4 className="mb-4 text-uppercase">Vidrio/Acrilico</h4>
                                    <ul>
                                        <li>Acrilico Brillante</li>
                                        <li>Mate</li>
                                        <li>Antireflejante</li>
                                        <li>Claro</li>
                                    </ul>
                                </div>
                                <div className="luna">
                                    <h4 className="mb-4 text-uppercase">Luna/Espejos</h4>
                                    <ul>
                                        <li>Bisel</li>
                                        <li>Canto pulido</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     );
}
 
export default ProductosMateriales;