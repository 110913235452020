import React from 'react';
import auditorio from '../../img/auditorio nacional.jpg';
import azteca from '../../img/azteca.png';
import banamex from '../../img/banamex.jpg';
import bancomer from '../../img/bancomer.png';
import cfe from '../../img/cfe.jpg';
import conaculta from '../../img/conaculta.jpg';
import conagua from '../../img/conagua.webp';
import ferring from '../../img/ferring.png';
import fund_aleman from '../../img/fundacion aleman.png';
import imss from '../../img/imss.svg';
import inah from '../../img/INAH.png';
import interjet from '../../img/interjet.jpg';
import ipn from '../../img/ipn logo.webp';
import estanquillo from '../../img/mestanquillo.jpg';
import mnba from '../../img/mnba.jpg';
import motorola from '../../img/Motorola-Logo.png';
import nissan from '../../img/nissan.jpg';
import alianza from '../../img/Nueva-alianza.jpg';
import pemex from '../../img/pemex.png';
import prendamex from '../../img/prendamex.jpg';
import sabritas from '../../img/sabritas.png';
import sanborns from '../../img/sanborns.jpg';
import sct from '../../img/sct.png';
import sears from '../../img/sears logo.png';
import sep from '../../img/sep.png';
import shcp from '../../img/shcp.jpg';
import tecnocasa from '../../img/tecnocasa.png';
import televisa from '../../img/televisa.png';
import unam from '../../img/unam.jpg';


const Clientes = () => {
    return ( 
        <section className='clientes_fondo' id='clientes'>
            <h1 className='text-center mt-5'>Nuestros Clientes</h1>
            <div className="contenedor_imagenes">
                <img src={auditorio} alt="imagen auditorio" className='img-fluid'/>
                <img src={azteca} alt="imagen azteca" className='img-fluid'/>
                <img src={banamex} alt="imagen banamex" className='img-fluid'/>
                <img src={bancomer} alt="imagen bancomer" className='img-fluid'/>
                <img src={cfe} alt="imagen cfe" className='img-fluid'/>
                <img src={conaculta} alt="imagen conaculta" className='img-fluid'/>
                <img src={conagua} alt="imagen conagua" className='img-fluid'/>
                <img src={ferring} alt="imagen ferring" className='img-fluid'/>
                <img src={fund_aleman} alt="imagen fundacion aleman" className='img-fluid'/>
                <img src={imss} alt="imagen imss" className='img-fluid'/>
                <img src={inah} alt="imagen inah" className='img-fluid'/>
                <img src={interjet} alt="imagen interjet" className='img-fluid'/>
                <img src={ipn} alt="imagen ipn" className='img-fluid'/>
                <img src={estanquillo} alt="imagen estanquillo" className='img-fluid'/>
                <img src={mnba} alt="imagen mnba" className='img-fluid'/>
                <img src={motorola} alt="imagen motorola" className='img-fluid'/>
                <img src={nissan} alt="imagen nissan" className='img-fluid'/>
                <img src={alianza} alt="imagen alianza" className='img-fluid'/>
                <img src={pemex} alt="imagen pemex" className='img-fluid'/>
                <img src={prendamex} alt="imagen prendamex" className='img-fluid'/>
                <img src={sabritas} alt="imagen sabritas" className='img-fluid'/> 
                <img src={sanborns} alt="imagen sanborns" className='img-fluid'/>
                <img src={sct} alt="imagen sct" className='img-fluid'/>
                <img src={sears} alt="imagen sears" className='img-fluid'/>
                <img src={sep} alt="imagen sep" className='img-fluid'/>
                <img src={shcp} alt="imagen shcp" className='img-fluid'/>
                <img src={tecnocasa} alt="imagen tecnocasa" className='img-fluid'/>
                <img src={televisa} alt="imagen televisa" className='img-fluid'/>
                <img src={unam} alt="imagen unam" className='img-fluid'/>
            </div>
        </section>
     );
}
 
export default Clientes;