import React from 'react';

const Servicios = () => {
    return ( 
        <div className="servicios mt-4 mb-4" id='servicios'>
            <div className="row aw-grid parte_1">
                <div className="col-md-8 revolucion">
                    <div className="row aw-grid ">
                        <div className=" col-12 col-xs-12 col-sm-12 col-md-6 d-flex flex-column align-items-center justify-content-center rev_info_1">
                            <h1 className="text-center font-weight-bold text-white">SERVICIOS</h1>
                        </div>
                        <div className="col-md-6 d-flex flex-column align-items-center justify-content-center rev_info_1 icons">
                            <div className="iconos d-flex align-items-center justify-content-center">
                                <div className="items-circle d-flex flex-column align-items-center justify-content-center mr-3">
                                    <i className="fas fa-camera"></i>
                                </div>
                                <div className="items-circle d-flex flex-column align-items-center justify-content-center mr-3">
                                    <i className="fas fa-tshirt"></i>
                                </div>
                                <div className="items-circle d-flex flex-column align-items-center justify-content-center mr-3">
                                    <i className="fas fa-key"></i>
                                </div>
                                <div className="items-circle d-flex flex-column align-items-center justify-content-center mr-3">
                                    <i className="fas fa-book"></i>
                                </div>
                                <div className="items-circle d-flex flex-column align-items-center justify-content-center mr-3">
                                    <i className="fas fa-paint-brush"></i>
                                </div>
                            </div>
                            <p className="text-uppercase text-lefth text-white mt-3">NUESTRO RETO ES INNOVAR <br />
                                TE MOSTRAMOS ALGUNAS <br />
                                COSAS QUE PODEMOS  <br />
                                ENMARCAR
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-xs-12 col-sm-12 col-md-4 d-flex flex-column grid-playera">
                    <div className="playera"></div>
                    <div className="marco_llave"></div>
                </div>
            </div>
            <div className="row aw-grid">
                <div className="col-12 col-xs-12 col-sm-12 col-md-6 pinceles"></div>
                <div className="col-12 col-xs-12 col-sm-12 col-md-6 abrazo"></div>
            </div>
        </div>
     );
}
 
export default Servicios;