import React from "react";
import { Card } from "react-bootstrap";

import logoRoma from "../../img/logoromaCompleto.png";

const SucursalesGuadalajara = () => {
  return (
    <section className="fondo_sucursales_guadalajara" id="sucursales">
      <h1 className="text-center mt-5 mb-5">Sucursales Guadalajara</h1>
      <div className="row aw-grid contenedor_sucursales container_Sucursales_Gua">
        <div className="col-md-8 colum_1 mt-5">
          <Card style={{ width: "18rem", height: "100%" }} bg="dark">
            <Card.Img variant="top" src={logoRoma} alt="logo marcos romas" />
            <Card.Body>
              <Card.Title className="text-white">Sucursal Garibaldi</Card.Title>
              <Card.Text className="text-white">Garibaldi 1338</Card.Text>
              <Card.Text className="text-white">
                Entre Ramos Millan y Clemente Orozco
              </Card.Text>
              <Card.Text className="text-white">
                Col.Santa Teresita C.P. 44600
              </Card.Text>
              <Card.Text className="text-white">Tel : 33 1656-7967</Card.Text>
            </Card.Body>
            <Card.Footer className="card_footer text-center">
              <a
                href="https://goo.gl/maps/SRuDqjykR2CRGvii6"
                className="text-decoration-none"
                target="_blank"
                rel="noopener noreferrer"
                title="Roma Cuauhtémoc"
              >
                Ver Ubicación
              </a>
            </Card.Footer>
          </Card>
        </div>
      </div>
    </section>
  );
};

export default SucursalesGuadalajara;
