import React from 'react';

import marco_principal from '../../img/marco_principal.png';
import submarco_1 from '../../img/submarco_1.png';
import marco_principal_2 from '../../img/marco_principal_2.png';
import submarco_2 from '../../img/submarco_2.png';

const HeroPrincipal = () => {
    return ( 
        <header className="hero" id='inicio'>
            <div className="titulo_principal">
                <div className="row aw-grid">
                    <div className="col-md-4 d-flex flex-column align-items-center justify-content-center titulos">
                        <h2 className="text-uppercase text-center">haz que cada</h2>
                        <h1 className="text-uppercase text-center">momento</h1>
                        <h3 className="text-uppercase text-center">dure para siempre</h3>
                    </div>
                    <div className="col-md-8 marcos_principal">
                        <img src={marco_principal} className="marco_principal" alt='imagen marco principal'/>
                        <img src={submarco_1} className="submarco_1" alt='imagen submarco 1'/>
                        <img src={marco_principal_2} className="marco_principal_2" alt='imagen marco principal 2'/>
                        <img src={submarco_2} className="submarco_2" alt='imagen submarco 2'/>
                    </div>
                </div>
            </div>
            <a href="http://wa.me/+525579197175?text=Deseo%20contactar%20con%20%20MarcosRoma%20%20" className="whatsapp" target="_blank" rel="noopener noreferrer">
                    <i className="fa fa-whatsapp whatsapp-icon"></i>
            </a>
        </header>
     );
}
 
export default HeroPrincipal;